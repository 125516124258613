import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom'; // Import NavLink from react-router-dom
import { LOGOUT, RENDER_URL } from '../../../common/Constants';
import { GlobalDispatchContext } from '../../../Context/Context';
import { ManageLocalStorage } from '../../../Core/LocalStorage';
import { ManageSessionStorage } from '../../../Core/SessionStorage';

const AdminTopMenu = () => {

    const dispatch = React.useContext(GlobalDispatchContext)
    let navigate = useNavigate();

    const onLogout = ()=>{
        clearBrowser();
    }

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        
        window.location = RENDER_URL.LOGIN_URL;
        
    }



    return (
  

            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                <div className="container-fluid">
                    <button className="btn btn-primary" id="sidebarToggle">
                        Toggle Menu
                    </button>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link" href="#!" onClick={()=>{
                                        onLogout();
                                }}>
                                   Logout
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </nav>

        



    );
};

export default AdminTopMenu;
