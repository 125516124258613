import GuestBibleWords from "./GuestBibleWords";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";
import GuestOurOfficials from "./GuestOurOfficials";
import GuestService from "./GuestService";
import GuestTagMaster from "./GuestTagMaster";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Confetti from 'react-confetti';
import { requestForToken, onMessageListener } from './../../Services/Notification/firebaseConfig';
import { BAVA_NEWS, RENDER_URL } from "../../common/Constants";
import { GlobalContext, GlobalDispatchContext } from "../../Context/Context";

const GuestContainer = () => {

    const location = useLocation(); // Access the location object

    const context = React.useContext(GlobalContext)
    const loginData = context.login;
    const dispatch = React.useContext(GlobalDispatchContext)

    const [isLaunchComplete, setIsLaunchComplete] = useState(false);

    const width = window.innerWidth - 100;
    const height = window.innerHeight;

    useEffect(() => {
        //requestForToken();
        /* if (loginData.isNewsShown!== true) {
            window.location.href = RENDER_URL.BAVA_BAD_NEW_URL;
        }
        else{
            dispatch({
                type: BAVA_NEWS,
                payload: false,
            });
        } */
        
    }, []);



    useEffect(() => {
        // Check if the passed state is present and if launchComplete is 1
        if (location.state && location.state.launchComplete === 1) {
            setIsLaunchComplete(true); // Enable the state
            setTimeout(() => {
                setIsLaunchComplete(false);
            }, [3000]);
        }
    }, [location.state]);


    onMessageListener()
        .then((payload) => {
            // setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });

            /* new Notification('MBJSOC ', {
                body: payload?.notification?.body,
            }); */

        })
        .catch((err) => console.log('failed: ', err));

    return (<>

        {isLaunchComplete && (
            <Confetti
                width={width}
                height={height}
            />
        )}

        <GuestHeader></GuestHeader>
        <GuestTagMaster></GuestTagMaster>
        <GuestService></GuestService>
        <GuestOurOfficials></GuestOurOfficials>
        <GuestBibleWords></GuestBibleWords>

        <GuestFooter></GuestFooter>

    </>)
}

export default GuestContainer;