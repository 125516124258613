import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom

const AdminSideMenu = () => {
  return (
    <div className="border-end bg-white" id="sidebar-wrapper">
      <div className="sidebar-heading border-bottom bg-light">
        Welcome Admin
      </div>
      <div className="list-group list-group-flush">
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/mass-services"
          activeClassName="active"
        >
          Mass/Services
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/shortcuts"
          activeClassName="active"
        >
          Shortcuts
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/overview"
          activeClassName="active"
        >
          Overview
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/events"
          activeClassName="active"
        >
          Events
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/profile"
          activeClassName="active"
        >
          Profile
        </NavLink>
        <NavLink
          className="list-group-item list-group-item-action list-group-item-light p-3"
          to="/admin/status"
          activeClassName="active"
        >
          Status
        </NavLink>
      </div>
    </div>
  );
};

export default AdminSideMenu;
