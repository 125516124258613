import React from 'react';

const EventTable = ({ events, onDelete }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title mb-4">Events List</h3>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Time</th>
                <th>Location</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr key={index}>
                  <td>{event.EventTitle}</td>
                  <td>{new Date(event.EventDate).toLocaleDateString()}</td>
                  <td>{event.EventTime || '-'}</td>
                  <td>{event.EventLocation}</td>
                  <td>{event.EventDesc || '-'}</td>
                  <td>
                    <button
                      onClick={() => onDelete(event.Id)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EventTable;