import React from 'react';

const AnnouncementTable = ({ announcements, onDelete }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title mb-4">Announcements List</h3>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Content</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {announcements.map((announcement, index) => (
                <tr key={index}>
                  <td>{announcement.AnnouceTitle}</td>
                  <td>{new Date(announcement.AnnouceDate).toLocaleDateString()}</td>
                  <td>{announcement.AnnouceDesc}</td>
                  <td>
                    <button
                      onClick={() => onDelete(announcement.Id)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementTable;