import React from 'react';

const Announcement = ({ AnnouceTitle, AnnouceDesc, AnnouceDate }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <h5 className="card-title">{AnnouceTitle}</h5>
          <small className="text-muted">{AnnouceDate}</small>
        </div>
        <p className="card-text mt-2">{AnnouceDesc}</p>
      </div>
    </div>
  );
};

export default Announcement;