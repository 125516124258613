import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Confetti from 'react-confetti';
import { Button } from 'primereact/button';

const GuestLaunch = () => {
    const [timer, setTimer] = useState(5); // Initial timer value
    const [isLaunched, setIsLaunched] = useState(false); // To track launch state
    const width = window.innerWidth - 100;
    const height = window.innerHeight;
    
    const navigate = useNavigate(); // Initialize useNavigate for navigation

    // Function to start the countdown
    const startCountdown = () => {
        setIsLaunched(true); // Set launch state to true when button is clicked

        // Set an interval to decrease the timer every second
        const countdownInterval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    clearInterval(countdownInterval); // Clear the interval when the timer reaches zero

                    // After reaching zero, wait for one second, then navigate to "/" with state
                    navigate("/", { state: { launchComplete: 1 } }); // Pass state when navigating
                    

                    return 0;
                }
            });
        }, 1000);
    };

    return (
        <>
            {/* Show confetti only when the timer reaches zero */}
            {timer === 0 && (
                <Confetti
                    width={width}
                    height={height}
                />
            )}

            <div style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
                <div className="text-center" style={{ height: '100%', marginTop: '15%' }}>
                    <p style={{ fontSize: '100px' }}>{timer}</p>

                    {/* Disable button after launching the countdown */}
                    <Button
                        label="Launch Website"
                        severity="danger"
                        onClick={startCountdown}
                        disabled={isLaunched} // Disable button after it's clicked
                    />
                </div>
            </div>
        </>
    );
};

export default GuestLaunch;
