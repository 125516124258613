import React from 'react';


const GuestBibleWords = () => {


    return (
        <div className="py-5 bg-light">
            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-10 col-xl-7">
                        <div className="text-center">
                            <div className="fs-4 mb-4 fst-italic">
                                "“Let us arise and go to Bethel; and I will make an altar there to God, who answered me in the day of my distress and has been with me in the way which I have gone.”"
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <img
                                    className="rounded-circle me-3"
                                    src="https://dummyimage.com/40x40/ced4da/6c757d"
                                    alt="..."
                                />
                                <div className="fw-bold">
                                    Genesis
                                    <span className="fw-bold text-primary mx-1">/</span>
                                    35:3
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GuestBibleWords;