import { useState } from "react";
import GuestTeam from "./GuestTeam";
import GuestHeader from "./GuestHeader";



const GuestAboutBaseiliosBava = () => {


    const [aboutUsData, setAboutUsData] = useState(null);

    let data = [
        {
            section_1: {
                name: "Mor Baselios Yeldo Bava",
                tag_title: "Maphrian of the East",
                tag_data: "Bakhdida (Karakosh), near Mosul, Iraq",
                image_url: "./assets/images/Tomb_of_St.Baselios_Yeldo.jpg",
                details: "Maphryono Mor Baselios Yeldho was born in a village called kooded (now known as Karakosh) near Mosul in Iraq where Morthsmooni and her 7 children suffered martyrdom. At a very young age he joined the Mor Bahnan Monastery and become a monk. In 1678 he was consecrated Maphryono (Catholicos) by the Patriarch of Antioch Moran Mor Ignatius Abdul Masih I. In 1685 at the age of 92, the holy father started the difficult mission to India at the request of Mor Thoma II of Malankara who informed the Patriarchate about the unpleasant situation of the Church here.The saintly Maphryono was accompanied by his brother, two monks and an Episcopo , but only three of them is believed to have reached Malankara. The saint expired on Saturday afternoon on 'Kanni 19' (Malayalam Calendar), 1685 and was entombed on the very next day in the sanctuary of Mar Thoma Cheriapally, Kothamangalam. Though the holy father had lived in this land for only a few days, his name has spread far and wide leaving a lasting mark in the history of Malankara Syrian Church. The mission undertaken by the saint was fulfilled to a large extent by his faithful associate, Metropolitan Mor Ivanios Hidayatullah (entombed at Mulanthuruthy Marthoman Church)."
            },
            section_2: {
                name: "St. Thomas Church at Kothamangalam,",
                tag_data: "",
                image_url: "./assets/images/cheriyapally1.jpg",
                details: `The St. Thomas Church at Kothamangalam, popularly known as Mar Thoma Cheriapally, where the Saint Baselios Yeldho is buried is one of the most prominent parishes in India. According to the popular belief, the first church at Kothamangalam, Martha Mariam Valiyapally, was established in the 4th century AD by a few Syriac Christian families who migrated from Paravur and Angamali. Among the later Christian inhabitants of the place included a few Syrian Knanaites who eventually intermingled with the local Christian community.
                By the 14th century Kothamangalam, a Syrian Christian Center, became a major hub for trade with the neighboring state of Tamilnadu. The Martha Mariam Valiyapally was the only Syriac Christian Church there at that time. By the middle of the 15th century some disputes surfaced in the Valiyapally which led to the separation of 18 families from there. It was these families who eventually built the historical Mar Thoma Cheriapally nearby. Initially a Cross was established there in 1455 at the land allotted by the local administrator. Later a small church was erected at the place which was rebuilt in 1504.`
            }
        }
    ]




    return (<>

        <>
            <GuestHeader></GuestHeader>
            <header className="py-5">
                <div className="container px-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-6">
                            <div className="text-center my-5">
                                <h1 className="fw-bolder mb-3">{data[0].section_1.name}</h1>
                                <p className="lead fw-normal text-muted mb-4">{data[0].section_1.tag_title} </p>
                                {/* <a className="btn btn-primary btn-lg" href="#scroll-target">Read our story</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* About section one*/}
            <section className="py-5 bg-light" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-5 mb-lg-0"
                                src={data[0].section_1.image_url}
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">About {data[0].section_1.name}</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                {data[0].section_1.details}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* About section two*/}
            <section className="py-5">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 order-first order-lg-last">
                            <img
                                className="img-fluid rounded mb-5 mb-lg-0"
                                src={data[0].section_2.image_url}
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">{data[0].section_2.name}</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                {data[0].section_2.details}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="py-5 bg-light" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-5 mb-lg-0"
                                src={data[0].section_1.image_url}
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">About {data[0].section_1.name}</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                {data[0].section_1.details}
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}




        </>


    </>)
}

export default GuestAboutBaseiliosBava;