import { httpCall, httpFormCall, httpLoginCall } from "../../../Core/HttpService"
import { API_URL } from "../../../common/Constants"

export const AddUpdateSponsors = params => {
    return httpCall({
        url: API_URL.SPONSOR_UPDATES,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const getSponsorList = params => {
    return httpCall({
        url: API_URL.SPONSORS_LIST,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const AddUpdateSundayEvents = params => {
    return httpCall({
        url: API_URL.ADD_UPCOMING_EVENTT,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getUpComingEvents = params => {
    return httpCall({
        url: API_URL.UPCOMING_EVENT_LIST,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const AddUpdateAnnoucements = params => {
    return httpCall({
        url: API_URL.ADD_ANNOUCEMENTS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getAnnoucements = params => {
    return httpCall({
        url: API_URL.GET_ANNOUCEMENTS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const AddUpdateLink = params => {
    return httpCall({
        url: API_URL.ADD_LINKS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getImportantLinks = params => {
    return httpCall({
        url: API_URL.GET_LINKS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};
