import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom

const GuestTeam = (props) => {


    const [ourTeamList, setOurTeamList] = useState([])

    useEffect(() => {
        if (props.team_data) {
            setOurTeamList(props.team_data)
        }

    }, [props])

    const chunkArray = (array, chunkSize) => {
        const results = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };


    const renderMembers = (members) => {


        const chunkedMembers = chunkArray(members, 5);

        return chunkedMembers.map((memberGroup, index) => (
            <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-5 justify-content-center mb-4" key={index}>
                {memberGroup.map((member, i) => (

                    <div className="col mb-5 mb-5 mb-xl-0" key={i}>
                        <div className="text-center">
                            <div className='rounded_div'>
                                <img className='rounded_img' src={member.photo_url} alt="" />
                            </div>

                            <h5 className="fw-bolder">{member.name}</h5>
                            <div className="fst-italic text-muted">{member.position}</div>
                        </div>
                    </div>

                ))}
            </div>
        ));


    }

    const renderTeams = () => {
        return ourTeamList.map((team, index) => (
            <div className="container px-5 my-5" key={index}>
                <div className="text-center">
                    <h2 className="fw-bolder">{team.team_name}</h2>
                    <p className="lead fw-normal text-muted mb-5">{team.team_msg}</p>
                </div>
                {renderMembers(team.members)}
            </div>
        ));
    };

    return (
        <section className="py-5 bg-light">
            {renderTeams()}
        </section>
    );
};

export default GuestTeam;
