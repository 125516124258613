import GuestBibleWords from "./GuestBibleWords";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";
import GuestOurOfficials from "./GuestOurOfficials";
import GuestService from "./GuestService";
import GuestTagMaster from "./GuestTagMaster";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Confetti from 'react-confetti';
import { requestForToken, onMessageListener } from './../../Services/Notification/firebaseConfig';
import { GlobalDispatchContext } from "../../Context/Context";
import { BAVA_NEWS } from "../../common/Constants";

const BavaPass = () => {

    const location = useLocation(); // Access the location object

    const dispatch = React.useContext(GlobalDispatchContext)

    useEffect(()=>{

        dispatch({
            type: BAVA_NEWS,
            payload: true,
        });

    },[])

    return (
        <>
            <GuestHeader />

            <section className="w-100" style={ {backgroundColor: 'black' }}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="w-100 h-100" style={{ backgroundColor: 'black' }}>
                            <div className="card-body p-0 mt-3 text-center">
                                {/* Desktop Image */}
                                <img
                                    src="./assets/images/bava_jsc_land.png"
                                    alt="Desktop Image"
                                    className="desktop-image"
                                    style={{ width: '75%', height: 'auto', objectFit: 'cover' }}
                                />

                                {/* Mobile Image */}
                                 <img 
                                    src="./assets/images/bava_jsc.jpg" 
                                    alt="Mobile Image" 
                                    className="mobile-image" 
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <button className="btn btn-default">Home</button>
                        </div>
                    </div>
                </div>
            </section>

            <GuestFooter />
        </>
    );
}

export default BavaPass;
