import React, { useState, useEffect, useRef } from 'react';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import AdminTopMenu from '../dashboard/AdminTopMenu';
import AdminSideMenu from '../dashboard/AdminSideMenu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AddMassTiming } from './MassService';
function MassContainer(props) {

    const [visible, setVisible] = useState(false);
    const toast = useRef(null);

    const [event_time, setEventTime] = useState("");
    const [event_name, setEventName] = useState("");
    const [event_church, setEventChurch] = useState("");
    const [event_day, setEventDay] = useState("");


    const [massDetails, setMassDetails] = useState([
        {
            "time": '12:30PM - 5:00PM',
            "event_name": "Morning Prayer & Holy Qurbana",
            "event_church": "Derby Church",
            "event_day": "First Sunday/Every Month"
        }
    ]);

    const onAddMassDetails = () => {

        if (isValid()) {

            let data = {
                "event_time": event_time,
                "event_name": event_name,
                "event_church": event_church,
                "event_day": event_day
            }

            AddMassTiming(data).then((response) => {

                if(response.data.success){
                    
                }

            }).catch((error) => {

            })


        }

    }

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
    }

    const isValid = () => {
        return true;
    }


    const editButton = (rowData) => {
        return <Button icon="pi pi-file-edit" rounded text raised severity="secondary" aria-label="Bookmark" />
    }
    const deleteButton = (rowData) => {
        return <Button icon="pi pi-trash" rounded text raised severity="danger" aria-label="Bookmark" />
    }

    return (<>

        <div className="d-flex" id="wrapper">
            <AdminSideMenu></AdminSideMenu>
            <div id="page-content-wrapper" style={{ width: '100%' }}>
                <AdminTopMenu></AdminTopMenu>
                <div className="container-fluid p-5">

                    <Toast ref={toast} />

                    <div className="row ">
                        <div className='col-lg-12'>
                            <p className='h4'>Mass Details</p>
                            <Button label="Add New" style={{ float: 'right' }} icon="pi pi-external-link" onClick={() => setVisible(true)} />
                        </div>

                    </div>
                    <div className='row'>
                        <div className="card">
                            <DataTable value={massDetails} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="time" header="Time"></Column>
                                <Column field="event_name" header="Service/Mass"></Column>
                                <Column field="event_church" header="Church Name"></Column>
                                <Column field="event_day" header="Day"></Column>
                                <Column body={editButton} header="Edit"></Column>
                                <Column body={deleteButton} header="Delete"></Column>
                            </DataTable>
                        </div>
                    </div>


                    <Dialog header="Mass/Service Details" visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>

                        <div className="col-lg-12">
                            <div className="card mt-4 p-3">
                                <div className='col-lg-12'>
                                    <div className="form-group">
                                        <label>Event Title</label>
                                        <input type="text" className="form-control" placeholder="Enter title" value={event_name} onChange={(e) => { setEventName(e.target.value) }} />
                                        <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="form-group">
                                        <label>Church Name</label>
                                        <input type="text" className="form-control" placeholder="Enter title" value={event_church} onChange={(e) => { setEventChurch(e.target.value) }} />
                                        <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="form-group">
                                        <label>Mass Time</label>
                                        <input type="text" className="form-control" placeholder="Enter title" value={event_time} onChange={(e) => { setEventTime(e.target.value) }} />
                                        <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="form-group">
                                        <label>Day</label>
                                        <input type="text" className="form-control" placeholder="Enter title" value={event_day} onChange={(e) => { setEventDay(e.target.value) }} />
                                        <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <input type='button' className='btn btn-lg btn-success' value="Add Mass Details" onClick={() => { onAddMassDetails() }} />
                                </div>
                            </div>


                        </div>

                    </Dialog>


                </div>
            </div>
        </div>
    </>);

}

export default MassContainer;