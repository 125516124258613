
import { useEffect, useState } from "react";
import { BASE_API_URL } from "../../common/Constants";

const GuestOrganisationGallery = (props) => {

    const [galleryData, setGalleryData] = useState([]);

    useEffect(() => {
        if (props.team_data) {
            setGalleryData(props.team_data);
        }
    }, [props])

    const renderImageTile = () => {

        return (Object.entries(galleryData).map(([index, value]) => {

            return <div className="col-lg-6" key={index}>

                <img
                    className="img-fluid rounded-3 mb-5"
                    src={`${BASE_API_URL}uploads/gallery/${value.photoUrl}`}
                    alt="..."
                />
            </div>
        }))




    }

    return (

        <section className="py-5 bg-white">
            <div className="container px-5 my-5">

                <div className="row gx-5">
                    {renderImageTile()}
                </div>
            </div>
        </section>


    )
}

export default GuestOrganisationGallery;