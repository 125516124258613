import React, { useState, useEffect } from 'react';

import { Password } from 'primereact/password';

import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';

import { GlobalDispatchContext } from "../../../Context/Context"
import { LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING } from '../../../common/Constants';
import { login } from './AdminService'
import './Login.css'
import { useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../../../Core/LocalStorage';
import { ManageSessionStorage } from '../../../Core/SessionStorage';

function AdminLoginContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");
    const [error_msg, setErrorMsg] = useState()

    useEffect(() => {
    }, [])

    const clearValidation = () => {
        setUserError("");
        setPasswordError("");
    }

    const onLoginButtonClick = () => {
        clearValidation();
        if (userName.length <= 0) {
            setUserError('Invalid Username');
        }

        else if (password.length < 8) {
            setPasswordError('Password must be at least 8 chars long');
        }
        else {
            clearValidation();
            loginApi();

        }
    }

    const loginApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const loginData = {
            email: userName,
            password: password,
            logintype: "normal"
        }

        login(loginData).then((response) => {

            if (response.data.success) {
                debugger;
                let userData = {
                    user_token: response.data.data.token,
                    info: response.data.data.info
                }


                ManageLocalStorage.set(USER_TOKEN, userData.user_token)
                ManageLocalStorage.set(USER_DATA, userData);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });

                dispatch({
                    type: STOP_LOADING,
                });


                _history(RENDER_URL.ADMIN_DASHBOARD_URL);

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.message);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    return (
        <main>
            <section id="counts" className="counts section-bg" style={{ height: '100vh' }}>
                <div className="container align-items-center" style={{ height: '80vh' }}>
                    <div className="row align-items-center" style={{ height: '80vh' }}>
                        <div className="col-md-6 col-sm-12">
                            <div className='sidenav'>
                                <div className="login-main-text">
                                    <h2>Derby<br />Church</h2>
                                    <p>Login to continue</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{ margin: 'auto' }}>
                            <div className="login-form">

                                <div className="form-group">
                                    <span className="p-float-label">
                                        <InputText id="username" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" />
                                        <label htmlFor="username">Username</label>
                                        <small id="username-help" className="p-error block">{username_error}</small>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <span className="p-float-label">
                                        <Password id="password" value={password} feedback={false} onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="password">Password</label>
                                        <small id="password-help" className="p-error block">{password_error}</small>
                                    </span>
                                </div>

                                {
                                    error_msg && <div className="form-group">
                                        <span className="p-float-label">
                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                        </span>
                                    </div>
                                }


                                <div className="form-group" style={{ float: 'right' }}>
                                    <Button label="Login" icon="pi pi-check" className="p-button-secondary" onClick={() => { onLoginButtonClick() }} autoFocus />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AdminLoginContainer;