import React, { useState, useEffect } from 'react'

import { requestForToken, onMessageListener } from './firebaseConfig';

const Notification = () => {
    const [notification, setNotification] = useState({ title: '', body: '' });



    useEffect(() => {
        if (notification?.title) {
            console.log(notification);
        }
    }, [notification])

    requestForToken();

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch((err) => console.log('failed: ', err));

    return (
        <></>
    )
}

export default Notification