import React, { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RENDER_URL } from '../../common/Constants';
import { TieredMenu } from 'primereact/tieredmenu';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

const GuestFooter = () => {

    const menuLeft = useRef(null);
    const toast = useRef(null);
    let navigate = useNavigate();

    const items = [
        {
            label: '',
            items: [
                {
                    label: 'Our Lady of Lourdes Catholic Church, Derby',
                    icon: 'pi pi-directions',
                    command: () => {
                        window.location="https://www.google.com/maps/dir//Our+Lady+of+Lourdes+Catholic+C+36+Uttoxeter+Rd+Mickleover+Derby+DE3+9GE/@52.905772,-1.5411674,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4879f702bf564747:0xeafb5d32b962409d!2m2!1d-1.5411674!2d52.905772?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                    }
                },
                {
                    label: `St John's Church, Carrington, Nottingham`,
                    icon: 'pi pi-directions',
                    command: () => {
                        window.location="https://www.google.com/maps/dir//St+John's+Church,+Carrington,+Mansfield+Rd,+Carrington,+Nottingham+NG5+2DP/@52.9755934,-1.1531964,17z/data=!4m17!1m7!3m6!1s0x4879c19ffc58c9b1:0x87cf8c7df4c24e08!2sSt+John's+Church,+Carrington!8m2!3d52.9755902!4d-1.1506215!16s%2Fm%2F04jklw9!4m8!1m0!1m5!1m1!1s0x4879c19ffc58c9b1:0x87cf8c7df4c24e08!2m2!1d-1.1503816!2d52.975581!3e3?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                    }

                },
            ]
        }

    ];


    return (

        <footer className="text-white text-center text-lg-start bg-dark">
            {/* Grid container */}
            <div className="container p-4">
                {/*Grid row*/}
                <div className="row mt-4">
                    {/*Grid column*/}
                    <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                        <h5 className="text-uppercase mb-4 text-center align-middle">Mor Baselios JSO Church Derby</h5>
                        

                    </div>
                    {/*Grid column*/}
                    {/*Grid column*/}
                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                    <div className='w-100 text-center'>
                            <img src="./assets/images/footer_logo.png" alt='...' className='img-responsive' style={{ width: '50%' }} />
                        </div>
                    </div>
                    {/*Grid column*/}
                    {/*Grid column*/}
                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                        Location
                        <address>
                            <strong>Mor Baselios JSO Church Derby</strong>
                            <br />
                        </address>


                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                        <Button label="Get Direction" icon="pi pi-directions" className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />

                        {/* <NavLink  to="https://www.google.com/maps/dir//Our+Lady+of+Lourdes+Catholic+C+36+Uttoxeter+Rd+Mickleover+Derby+DE3+9GE/@52.905772,-1.5411674,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4879f702bf564747:0xeafb5d32b962409d!2m2!1d-1.5411674!2d52.905772?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" className='btn btn-sm btn-info'>Get Directions</NavLink> */}
                    </div>
                    {/*Grid column*/}
                </div>
                {/*Grid row*/}
            </div>
            {/* Grid container */}
            {/* Copyright */}
            <div
                className="text-center p-3"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                &copy; All rights reserved to morbaseliosjsocderby.org
            </div>
            {/* Copyright */}
        </footer>

    )
}

export default GuestFooter;