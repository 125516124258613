import React from 'react';

const Sidebar = ({ show, activeMenu, onMenuSelect }) => {
  const menuItems = [
    { id: 'sponsors', icon: 'people-fill', label: 'Qurbana Sponsors' },
    { id: 'events', icon: 'calendar-event', label: 'Events' },
    { id: 'announcements', icon: 'megaphone', label: 'Announcements' },
    { id: 'links', icon: 'link', label: 'Important Links' }
  ];

  return (
    <div className={`sidebar bg-dark text-white ${show ? 'show' : ''}`}>
      <div className="d-flex flex-column p-3" style={{ width: '280px' }}>
        {menuItems.map(item => (
          <button
            key={item.id}
            className={`btn btn-link text-white text-decoration-none text-start mb-2 ${
              activeMenu === item.id ? 'active' : ''
            }`}
            onClick={() => onMenuSelect(item.id)}
          >
            <i className={`bi bi-${item.icon} me-2`}></i>
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;