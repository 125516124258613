import React, { useState } from 'react';
import TopNav from './TopNav';
import Sidebar from './Sidebar';
import QurbanaSponsorManagement from '../sundayupdates/QurbanaSponsorManagement';
import EventManagement from '../sundayupdates/events/EventManagement';
import AnnouncementManagement from '../sundayupdates/annouce/AnnouncementManagement';
import ImportantLinkManagement from '../sundayupdates/importantlink/ImportantLinkManagement';
const Dashboard = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeMenu, setActiveMenu] = useState('sponsors');

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleMenuSelect = (menuId) => {
    setActiveMenu(menuId);
    if (window.innerWidth < 992) {
      setShowSidebar(false);
    }
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'sponsors':
        return <QurbanaSponsorManagement />;
      case 'events':
        return <EventManagement />;
      case 'announcements':
        return <AnnouncementManagement />;
      case 'links':
        return <ImportantLinkManagement />;
      default:
        return <QurbanaSponsorManagement />;
    }
  };

  return (
    <div className="dashboard">
      <TopNav toggleSidebar={toggleSidebar} />
      <div className="d-flex">
        <Sidebar 
          show={showSidebar} 
          activeMenu={activeMenu} 
          onMenuSelect={handleMenuSelect}
        />
        <main className={`flex-grow-1 p-3 ${showSidebar ? 'content-shifted' : ''}`}>
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;