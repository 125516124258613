import React, { useEffect } from 'react';

const SantaFriendList = ({ data }) => {

    useEffect(() => {
        console.log(data);
    }, [data])

    if (!data.success) {
        return <div>Error: Unable to fetch data.</div>;
    }

    return (
        <div className='row text-white'>
            <div className='col-lg-4 offset-4'>
                <h2>Santa and Friend List FOR TESTING</h2>
                <p>Status: {data.message}</p>
                <table className='table table-responsive text-white text-center'>
                    <thead>
                        <tr>
                            <th>Santa Name</th>
                            <th>Friend Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.SantaName}</td>
                                <td>{item.FriendName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SantaFriendList;
