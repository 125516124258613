import { API_URL } from "../../../common/Constants";
import { httpCallNoAuth } from "../../../Core/HttpService";


export const addSecretSantaDetails = params => {
    return httpCallNoAuth({
        url: API_URL.SECRET_SANTA_ADD,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const findMyFriendSanta = params => {
    return httpCallNoAuth({
        url: API_URL.SECRET_SANTA_FIND,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const mixSanta = params => {
    return httpCallNoAuth({
        url: '/admin/mix_santa',
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};
