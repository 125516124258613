import React, { useEffect, useState } from 'react';
import EventForm from './EventForm';
import EventTable from './EventTable';
import { AddUpdateSundayEvents, getUpComingEvents } from '../NoticeUpdateService';

const EventManagement = () => {
  const [events, setEvents] = useState([
    {
      title: "Christmas Qurbana",
      date: "2024-12-24",
      time: "16:00",
      location: "St Johns Church, Nottingham",
      description: "Christmas Eve Holy Qurbana Service"
    },
    {
      title: "Christmas Carol - Round 1",
      date: "2024-12-14",
      time: "",
      location: "Nottingham, Ilkiston, Chesterfield, Burton, Ashbourn",
      description: "Christmas Carol service outside Derby & Nottingham"
    }
  ]);

  useEffect(() => {


    loadUpComingEvents();

  }, []);

  const loadUpComingEvents = () => {

    let filterData = {
      id: ''
    }

    getUpComingEvents().then((response) => {

      if (response.data.success) {
        console.log(response.data.data);
        setEvents(response.data.data)
      }

    }).catch((error) => {
      setEvents([])
    })

  }


  const handleSubmit = (formData) => {
    const newEvent = { ...formData, ...{ activeStatus: 1 } };
    const jsonData = JSON.stringify(newEvent);

    AddUpdateSundayEvents(jsonData).then((response) => {

      if(response){
        loadUpComingEvents();
      }

    }).catch((error) => {

    })

    console.log('Event data to be sent to backend:', jsonData);
  };

  const handleDelete = (delete_id) => {

    const jsonData = JSON.stringify({ id: delete_id, activeStatus: 0, deleteStatus: 1 });
    //we are updating the status here 
    AddUpdateSundayEvents(jsonData).then((response) => {

      if (response.data.success) {
        loadUpComingEvents();
      }

    }).catch((error) => {
      alert(error);
    })

  };

  return (
    <div className="container py-4">
      <h2 className="text-center mb-4">Event Management</h2>
      <div className="row">
        <div className="col-12">
          <EventForm onSubmit={handleSubmit} />
        </div>
        <div className="col-12">
          <EventTable
            events={events}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default EventManagement;