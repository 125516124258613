import React from 'react';


const GuestTagMaster = () => {



    return (
        <header>
            <div className="container px-5">
                <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-lg-8 col-xl-7 col-xxl-6">
                        <div className="my-5 text-center text-xl-start">
                            <h1 className="display-5 fw-bolder text-black mb-2">
                                Mor Baselios Church Derby
                            </h1>
                            <p className="lead fw-normal text-black-50 mb-4">
                                “മധ്യസ്ഥനാം മോർ ബസേലിയോസ്‌ ബാവ പ്രാർത്ഥിക്കണമേ ഞങ്ങൾക്കായ്”
                            </p>
                            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                {/* <a className="btn btn-primary btn-lg px-4 me-sm-3" href="#features">
                                    Get Started
                                </a>
                                <a className="btn btn-outline-light btn-lg px-4" href="#!">
                                    Learn More
                                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-xxl-6 d-xl-block text-center">
                        <img
                            className="img-fluid rounded-3 my-5"
                            src="./assets/images/Tomb_of_St.Baselios_Yeldo.jpg"
                            alt="..."
                        />
                    </div>
                </div>
            </div>
        </header>


    )
}

export default GuestTagMaster;