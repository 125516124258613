import React, { useState, useRef } from "react";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";

const GuestCandle = () => {
    const [isFlameOn, setIsFlameOn] = useState(true); // Manage flame state
    const [isVideoPlaying, setIsVideoPlaying] = useState(false); // Manage video play state
    const videoRef = useRef(null); // Reference to the video element

    const toggleFlame = () => {
        setIsFlameOn((prev) => !prev);
    };

    // Handle video play when clicking the play button
    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsVideoPlaying(true); // Hide play button when video is playing
        }
    };

    return (
        <>
            <GuestHeader />

            <section className="candle_section text-center">
                <div className="video-container">
                    {/* Video element with a ref */}
                    <video
                        ref={videoRef}
                        src="https://api.morbaseliosjsocderby.org/uploads/IMG_8972.mp4"
                        width="250px"
                        preload="true"
                        onEnded={() => setIsVideoPlaying(false)} // Show play button when video ends
                    />

                    {/* Play button overlay */}
                    {!isVideoPlaying && (
                        <button className="rounded-circle play-button" onClick={handlePlayVideo}>
                            <i className="pi pi-play-circle" style={{ color: 'slateblue', fontSize: '48px' }}></i>
                        </button>
                    )}
                </div>

                <div className="candle">
                    {/* The flame will fade in/out based on the isFlameOn state */}
                    <div className={`flame ${isFlameOn ? "flame-on wobble" : "flame-off"}`}>
                        <div className="shadows" />
                        <div className="top" />
                        <div className="middle" />
                        <div className="bottom" />
                    </div>

                    <div className={`wick ${isFlameOn ? "" : "smoke-on"}`} /> {/* Add smoke animation when the flame is off */}
                    <div className="wax" />
                </div>
            </section>

            {/* CSS for video container and play button overlay */}
            <style jsx>{`
                .video-container {
                    position: relative;
                    display: inline-block;
                    margin: 0 auto; /* Center the video container */
                }
                
                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: transparent;
                    border: none;
                    cursor: pointer;
                }

                .candle_section {
                    display: flex;
                    flex-direction: column;
                    align-items: center; /* Center all elements in the section */
                }
            `}</style>
        </>
    );
};

export default GuestCandle;
