import React from 'react';

const QurbanaSponsors = ({ sponsors }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Qurbana Sponsors</h5>
        <ul className="list-group list-group-flush mt-3">
          {sponsors.map((sponsor, index) => (
            <li key={index} className="list-group-item">{sponsor.SponsorName}  {sponsor.SponsorDesc === "" ? "" : `(${sponsor.SponsorDesc})` } - {sponsor.Date} </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QurbanaSponsors;