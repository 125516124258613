import React, { useEffect, useState } from 'react';
import ImportantLinkForm from './ImportantLinkForm';
import ImportantLinkTable from './ImportantLinkTable';
import { AddUpdateLink, getImportantLinks } from '../NoticeUpdateService';

const ImportantLinkManagement = () => {
  const [links, setLinks] = useState([
    {
      title: "2025 Qurbana Sponsor Registration",
      url: "https://forms.gle/HYssymVW4WvKZenM9",
      description: "Fill out this form to register for 2025 Qurbana sponsorship"
    }
  ]);

  useEffect(() => {

    loadImportantLinks();

  }, []);


  const loadImportantLinks = () => {

    let filterData = {
      id: ''
    }

    getImportantLinks().then((response) => {

      if (response.data.success) {
        console.log(response.data.data);
        setLinks(response.data.data)
      }

    }).catch((error) => {
      setLinks([])
    })

  }

  const handleSubmit = (formData) => {
    const newLink = { ...formData, ...{ activeStatus: 1 } };

    const jsonData = JSON.stringify(newLink);

    AddUpdateLink(jsonData).then((response) => {

      if (response) {
        loadImportantLinks();
      }

    }).catch((error) => {
        loadImportantLinks();
    })



  };

  const handleDelete = (delete_id) => {
    
    const jsonData = JSON.stringify({ id: delete_id, activeStatus: 0, deleteStatus: 1 });
    //we are updating the status here
    AddUpdateLink(jsonData).then((response) => {

      if (response.data.success) {
        loadImportantLinks();
      }

    }).catch((error) => {
      alert(error);
    })

  };

  return (
    <div className="container py-4">
      <h2 className="text-center mb-4">Important Links Management</h2>
      <div className="row">
        <div className="col-12">
          <ImportantLinkForm onSubmit={handleSubmit} />
        </div>
        <div className="col-12">
          <ImportantLinkTable
            links={links}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportantLinkManagement;