import React, { useState } from 'react';
import GuestHeader from '../GuestHeader';
import './secret.css';
import SecretSantaRegistration from './SecretSantaRegistration';
import SantaFriendList from './SantaFriendList';
import SecretSantaMatch from './SecretSantaMatch';

const SecretSantaContainer = () => {

    const [testMixData, setTestMixData] = useState({
        "success": true,
        "message": "MIXED",
        "data": []
    });

    // Get the current URL path
    const currentPath = window.location.pathname;

    return (
        <>
            <GuestHeader />
            <section className='container-fuild bg-christmas'>
                <div className='row'>
                    <div className='mt-2 col-lg-12 col-sm-12 text-center bg-white'>
                        <span className='h4'>UNDER DEVELOPMENT - TRIAL RUN ONLY</span>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-lg-12 col-sm-12 col-md-12 text-center'>
                        <span className='title'>Merry Christmas</span>
                    </div>
                    <div className='col-lg-12 col-sm-12 col-md-12 text-center text-white'>
                        Secret 🎅 Closes on December 20th! 🎄Register now to be part of this year’s Secret Santa!
                    </div>
                </div>

                <div className='row mt-2'>
                    {
                        currentPath === '/christmas' && (
                            <div className='col-lg-4 offset-lg-2 col-sm-12 col-md-6'>
                                <SecretSantaRegistration onMix={(data) => {
                                    setTestMixData(data);
                                }} />
                            </div>
                        )
                    }
                    {
                        currentPath === '/checksecret' && (
                            <div className='col-lg-4 offset-lg-2 col-sm-12 col-md-6'>
                                <SecretSantaMatch />
                            </div>
                        )
                    }

                    <div className='col-lg-4 col-sm-12 col-md-6 text-center'>
                        <img className='santa_appopan img img-responsive' src='./santa.svg' alt='Santa' />
                        <span className='text-center santa_secret'>Keep it a secret</span>
                    </div>
                </div>


                {currentPath === '/christmas' && (
                    <>
                        <div className='row mt-2'>
                            <div className='col-sm-12 col-lg-8 offset-lg-2'>
                                <span className='text-center santa_secret'>How this works !!!</span>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-lg-8 offset-lg-2'>
                                <ul className='text-white'>
                                    <li>
                                        After registration closes, you can discover your Christmas Friend by revisiting this page. Simply enter your Name and Date of Birth (DOB) to see who you’ll be gifting! Get ready to spread the holiday cheer! 🎉
                                    </li>
                                    <li className='mt-2'>
                                        Register with Fullname and Date of birth in mandatory since we are using these details to show you your secret friend later when you visit.
                                    </li>
                                    <li className='mt-2'>
                                        It will be great if you wrap up your gift and place the gift in the desired location to put the gift.
                                    </li>
                                    <li className='mt-2'>
                                        Gifts should be labeled with the full name of your secret Santa friend along with their family name to avoid confusion and miss-gifting.
                                    </li>
                                    <li className='mt-2'>
                                        Everyone should try to bring the gift on December 24.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='row'>
                    <SantaFriendList data={testMixData} />
                </div>

                    </>)}

                
            </section>
        </>
    );
};

export default SecretSantaContainer;