import React, { useEffect, useState } from 'react';
import { BASE_API_URL } from '../../common/Constants';
import { loadEventGallery } from './GuestApiServices';


const GuestService = () => {

    const [sliderData, setSliderData] = useState([]);

    useEffect(() => {
        loadOrganisationGallery();
    }, []);

    const loadOrganisationGallery = () => {

        let data = {
            eventId: 5
        }

        loadEventGallery(data).then((response) => {
            console.log(response);
            if (response.data.success) {
                setSliderData(response.data.data)
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const renderSlider = () => {

        return Object.entries(sliderData).map((([index, value]) => {

            return <div key={index} className="carousel-item active" data-bs-interval={5000}>
                <img
                    src={`${BASE_API_URL}uploads/gallery/${value.photoUrl}`}
                    className="d-block w-100"
                    alt="..."
                />
            </div>

        }))

    }


    return (
        <section className="service_slider mt-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 text-center">
                        <div className="card shadow service_card">
                            <div className="card-body">
                                <img
                                    className="cross_logo"
                                    src="https://st5.depositphotos.com/46009160/67475/v/380/depositphotos_674754162-stock-illustration-christian-holy-cross-sun-rays.jpg"
                                    alt="Customer 3"
                                />
                                <div className="col-auto mt-3">
                                    <h2 className="reviewTitle">Service Time and Schedule</h2>
                                    <div className="list-group">
                                        <a
                                            href="https://www.google.com/maps/dir//Our+Lady+of+Lourdes+Catholic+C+36+Uttoxeter+Rd+Mickleover+Derby+DE3+9GE/@52.905772,-1.5411674,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4879f702bf564747:0xeafb5d32b962409d!2m2!1d-1.5411674!2d52.905772?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                                            className="list-group-item list-group-item-action"
                                            aria-current="true"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <small>12:30PM - 5:00PM</small>
                                                <h5 className="mb-1">Morning Prayer &amp; Holy Qurbana</h5>
                                                <small>First Sunday</small>
                                            </div>
                                            <small>Our Lady of Lourdes Catholic Church,36 Uttoxeter Road,Mickleover<br></br>
                                            Derby, DE3 9GE</small>
                                        </a>

                                        <a
                                            href="https://www.google.com/maps/dir//St+John's+Church,+Carrington,+Mansfield+Rd,+Carrington,+Nottingham+NG5+2DP/@52.9755934,-1.1531964,17z/data=!4m17!1m7!3m6!1s0x4879c19ffc58c9b1:0x87cf8c7df4c24e08!2sSt+John's+Church,+Carrington!8m2!3d52.9755902!4d-1.1506215!16s%2Fm%2F04jklw9!4m8!1m0!1m5!1m1!1s0x4879c19ffc58c9b1:0x87cf8c7df4c24e08!2m2!1d-1.1503816!2d52.975581!3e3?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                                            className="list-group-item list-group-item-action"
                                            aria-current="true"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <small>12:30PM - 5:00PM</small>
                                                <h5 className="mb-1">Morning Prayer &amp; Holy Qurbana</h5>
                                                <small>Third Sunday</small>
                                            </div>
                                            <small>St. John's Church Mansfield Road  <br></br>
                                            Carrington,Nottingham NG5 2DP</small>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div
                            id="carouselExampleInterval"
                            className="carousel slide"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-inner">
                                {renderSlider()}
                            </div>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleInterval"
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleInterval"
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default GuestService;