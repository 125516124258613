import React from 'react';

const ImportantLink = ({ LinkTitle, LinkUrl, LinkDesc }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{LinkTitle}</h5>
        <p className="card-text my-2">{LinkDesc}</p>
        <a 
          href={LinkUrl} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-primary text-decoration-underline"
        >
          {LinkUrl}
        </a>
      </div>
    </div>
  );
};

export default ImportantLink;