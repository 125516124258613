import { useState } from "react";
import GuestTeam from "./GuestTeam";



const GuestAboutUsDetails = () => {


    const [ourManagement, setOurManagement] = useState([
        {
            "team_name": "Management",
            "team_msg": "",
            "members": [
                {
                    "name": "Fr Basil Benny",
                    "position": "Vicar",
                    "phone": "",
                    "photo_url": "./assets/images/management/basil.png"
                },
                {
                    "name": "Shibu George",
                    "position": "Secretary",
                    "phone": "",
                    "photo_url": "./assets/images/management/shibu.png "
                },
                {
                    "name": "Aby Abraham K ",
                    "position": "Trustee ",
                    "phone": "",
                    "photo_url": "./assets/images/management/aby.png "
                },
                {
                    "name": "Robin Thomas",
                    "position": "Joint Secretary",
                    "phone": "",
                    "photo_url": "./assets/images/management/robin.png "
                },
                {
                    "name": "Shibu Mathew",
                    "position": "Headmaster Sundayschool",
                    "phone": "",
                    "photo_url": "./assets/images/management/shibuM.png "
                },
                {
                    "name": "Teena Thomas",
                    "position": "Secretary Vanithasamajam",
                    "phone": "",
                    "photo_url": "./assets/images/management/teena.png "
                },
                {
                    "name": "Binju Jacob",
                    "position": "Joint Trustee",
                    "phone": "",
                    "photo_url": "./assets/images/management/binju.png "
                },
                {
                    "name": "Philip Alias",
                    "position": "Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/philip.png "
                },
                {
                    "name": "Eldho Joseph",
                    "position": "Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/eldho.png "
                },
                {
                    "name": "Nisha Peter",
                    "position": "Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/nisha.png "
                },
                {
                    "name": "Jijin George Kunnathu",
                    "position": "Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/jijin.png "
                },
                {
                    "name": "Rony Thomas",
                    "position": "Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/rony.png "
                }, {
                    "name": "Eldhose Kuriakose",
                    "position": "Ex.Officio",
                    "phone": "",
                    "photo_url": "./assets/images/management/eldhose.png "
                },
                {
                    "name": "Joby Joseph",
                    "position": "Ex.Officio",
                    "phone": "",
                    "photo_url": "./assets/images/management/joby.png "
                },
                {
                    "name": "Dr.Sajith Paul",
                    "position": "UK Council Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/sajith.png "
                },
                {
                    "name": "Rajesh Kuruvilla",
                    "position": " Member",
                    "phone": "",
                    "photo_url": "./assets/images/management/rajesh.png "
                },

            ]
        }
    ])



    return (<>

        <>

            <header className="py-5">
                <div className="container px-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-6">
                            <div className="text-center my-5">
                                <h1 className="fw-bolder mb-3">MOR BASELIOS JACOBITE SYRIAN ORTHODOX CHURCH</h1>
                                <p className="lead fw-normal text-muted mb-4"> Under Malankara
                                    Syrian Orthodox Church's (MSOC) Patriarchal Vicariate in the UK</p>
                                {/* <a className="btn btn-primary btn-lg" href="#scroll-target">Read our story</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* About section one*/}
            <section className="py-5 bg-light" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-5 mb-lg-0"
                                src="./assets/images/7158.jpg"
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">Our founding</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                Mor Baselios Jacobite Syrian Orthodox Church, Derby was founded as part of the Malankara
                                Syrian Orthodox Church's (MSOC) Patriarchal Vicariate in the UK.
                                The Church was started with prayers of the faithful and first Holy Qurbana
                                was celebrated at St Peter's Church, Derby by Rev Fr. Siju Varghese Kaungampillil
                                on 23rd of December 2009. Our Patriarchal Vicar at that time was
                                <b> Dr. Geevarghese Mor Coorilos Metropolitan.</b>
                                Vicars who have served our Church include Rev Fr Siju Varghese, Rev Fr Peter Kuriakose,
                                late Rev Fr Biji Markose and Rev Fr Johnson Peter.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* About section two*/}
            <section className="py-5">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 order-first order-lg-last">
                            <img
                                className="img-fluid rounded mb-5 mb-lg-0"
                                src="./assets/images/IMG_1902.jpg"
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">Growth &amp; beyond</h2>
                            <p className="lead fw-normal text-muted mb-0">
                                Until 2020, monthly Holy Qurbana services were held at St. Joseph's Catholic Church, Derby.
                                Our Church has grown with members of Syriac Jacobite Christian community from Derby
                                and Nottingham.
                                Rev Fr Basil Benny is the current Vicar of our Church.
                                We now celebrate Holy Qurbana at Derby on 1st Sunday
                                and at Nottingham on 3rd Sunday of every month.
                                Prayers are held at Our Lady of Lourdes Church, Mickleover, Derby
                                and St. John's Church, Carrington Nottingham respectively.
                                Sunday school for children,Vanitha Samajam,Youth Association and MGJSM are conducted regularly
                                as part of faithful organisation within the Church. Additionaly two prayer groups are also formed in our church - St Mary's Prayer Group & St Paul's Prayer Group.


                            </p>
                        </div>
                    </div>
                </div>
            </section>

           {/*  <GuestTeam team_data={ourManagement}></GuestTeam> */}


        </>


    </>)
}

export default GuestAboutUsDetails;