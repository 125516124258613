import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import { GlobalDispatchContext } from '../../../Context/Context';
import { useNavigate } from 'react-router-dom';
import { LOGOUT, RENDER_URL } from '../../../common/Constants';
import { ManageLocalStorage } from '../../../Core/LocalStorage';
import { ManageSessionStorage } from '../../../Core/SessionStorage';

const TopNav = ({ toggleSidebar }) => {

  const dispatch = React.useContext(GlobalDispatchContext)
  let navigate = useNavigate();

  const onLogout = ()=>{
      clearBrowser();
  }

  const clearBrowser = (e) => {
      dispatch({
          type: LOGOUT,
      });

      ManageLocalStorage.clear();
      ManageSessionStorage.clear();
      
      window.location = RENDER_URL.LOGIN_URL;
      
  }

  return (
    <Navbar bg="dark" variant="dark" className="mb-3">
      <Container fluid>
        <Button
          variant="outline-light"
          className="d-lg-none me-2"
          onClick={toggleSidebar}
        >
          <i className="bi bi-list"></i>
        </Button>
        <Navbar.Brand>Church Admin Dashboard</Navbar.Brand>
        <Button variant="outline-light" className="ms-auto" onClick={()=>{
          
          onLogout();

        }}>
          <i className="bi bi-box-arrow-right me-2"></i>
          Logout
        </Button>
      </Container>
    </Navbar>
  );
};

export default TopNav;