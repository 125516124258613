import React from 'react';

const Event = ({ EventTitle, EventDate, EventTime, EventLocation, EventDesc }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{EventTitle}</h5>
        <div className="mt-2">
          <div className="d-flex mb-1">
            <span className="fw-bold me-2">Date:</span>
            <span>{EventDate}</span>
          </div>
          <div className="d-flex mb-1">
            <span className="fw-bold me-2">Time:</span>
            <span>{EventTime}</span>
          </div>
          <div className="d-flex mb-1">
            <span className="fw-bold me-2">Location:</span>
            <span>{EventLocation}</span>
          </div>
        </div>
        <p className="card-text mt-2">{EventDesc}</p>
      </div>
    </div>
  );
};

export default Event;