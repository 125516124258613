import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { history } from "./Core/Store"
import _Routes from './Core/Routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


export default function RouterApp(props) {



  return (

    <_Routes history={history} />

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<RouterApp />);

// test
serviceWorkerRegistration.register();

reportWebVitals();
