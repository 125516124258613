import React, { useEffect, useState } from "react";
import { loadPostEventsDetails } from "./GuestApiServices";
import { BASE_API_URL } from "../../common/Constants";


const GuestPostTile = (props) => {

    useEffect(()=>{

        console.log(props);
    },[props])

    const [postEventDetailsList, setPostEventDetails] = useState([]);

    useEffect(() => {
        loadPostEvents();
    }, []);

    const loadPostEvents = () => {

        loadPostEventsDetails().then((response) => {

            if (response.data.success) {
                console.log(response.data.data);
                if (response.data.data.length > 0) {
                    setPostEventDetails(response.data.data)
                }
            }

        }).catch((error) => {
            console.log(error);
        })

    }

    const renderEachPostTile = () => {


        return Object.entries(postEventDetailsList).map(([i, data]) => {
            return <div className="col-lg-4 mb-5" key={i}>
                <div className="card h-100 shadow border-0" style={{cursor:"pointer"}} onClick={()=>{props.onPostSelected(data.id)}} >
                    <img
                        className="card-img-top"
                        src={`${BASE_API_URL}uploads/gallery/${data.masterThumbnailUrl}`}
                        alt="..."
                        onClick={()=>{props.onPostSelected(data.id)}}
                    />
                    <div className="card-body p-4">
                        <div className="badge bg-primary bg-gradient rounded-pill mb-2">
                            Event
                        </div>
                    
                            <div className="h5 card-title mb-3" >{data.eventName}</div>
        
                        <p className="card-text mb-0">
                            {data.eventDescription}
                        </p>
                    </div>
                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                        <div className="d-flex align-items-end justify-content-between">
                            <div className="d-flex align-items-center">
                                <img
                                    className="rounded-circle me-3"
                                    src="https://dummyimage.com/40x40/ced4da/6c757d"
                                    alt="..."
                                />
                                <div className="small">
                                    <div className="fw-bold">MBJSOC Derby</div>
                                    <div className="text-muted">{data.eventDate}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        });




    }


    return (

        <>

            {setPostEventDetails.length > 0 ? renderEachPostTile() : "No Events"}

        </>


    );

}


export default GuestPostTile;