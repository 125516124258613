import { httpCall,httpFormCall, httpLoginCall } from "../../../Core/HttpService"
import { API_URL } from "../../../common/Constants"

export const AddMassTiming = params => {
    return httpCall({
        url: API_URL.LOGIN_DASHBOARD,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

