import { httpCall,httpCallNoAuth,httpFormCall, httpLoginCall } from "../../Core/HttpService"
import { API_URL } from "../../common/Constants"

export const loadPostEventsDetails = params => {
    return httpCallNoAuth({
        url: API_URL.POST_EVENTS,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};




export const loadEventGallery = params => {
    return httpCallNoAuth({
        url: API_URL.POST_EVENTS_GALLERY,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const sendEmail = params => {
    return httpCallNoAuth({
        url: API_URL.SEND_EMAIL,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const loadUpdate = params => {
    return httpCallNoAuth({
        url: API_URL.SUNDAY_UPDATES,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

