import React, { useEffect, useState } from 'react';
import AnnouncementForm from './AnnouncementForm';
import AnnouncementTable from './AnnouncementTable';
import { AddUpdateAnnoucements, getAnnoucements } from '../NoticeUpdateService';

const AnnouncementManagement = () => {
  const [announcements, setAnnouncements] = useState([]);


  useEffect(()=>{
      loadAnnoucements();
  },[]);


  const loadAnnoucements = () => {

    let filterData = {
      id: ''
    }

    getAnnoucements().then((response) => {

      if (response.data.success) {
        console.log(response.data.data);
        setAnnouncements(response.data.data)
      }

    }).catch((error) => {
      setAnnouncements([])
    })

  }


  const handleSubmit = (formData) => {
    const newAnnouncement = { ...formData, ...{ activeStatus: 1 } };

    const jsonData = JSON.stringify(newAnnouncement);

    AddUpdateAnnoucements(jsonData).then((response) => {

      if (response) {
        loadAnnoucements();
      }

    }).catch((error) => {
      loadAnnoucements();
    })

  };

  const handleDelete = (delete_id) => {

    const jsonData = JSON.stringify({ id: delete_id, activeStatus: 0, deleteStatus: 1 });
    AddUpdateAnnoucements(jsonData).then((response) => {
      if (response) {
        loadAnnoucements();
      }

    }).catch((error) => {
      loadAnnoucements();
    })
  };

  return (
    <div className="container py-4">
      <h2 className="text-center mb-4">Announcement Management</h2>
      <div className="row">
        <div className="col-12">
          <AnnouncementForm onSubmit={handleSubmit} />
        </div>
        <div className="col-12">
          <AnnouncementTable
            announcements={announcements}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementManagement;