import { useEffect, useState } from "react";
import GuestTeam from "./GuestTeam";
import GuestHeader from "./GuestHeader";
import { useLocation } from "react-router-dom";
import { RENDER_URL } from "../../common/Constants";
import { loadEventGallery } from "./GuestApiServices";
import GuestOrganisationGallery from "./GuestOrganisationGallery";



const GuestAdministration = () => {

    const [ourManagement, setOurManagement] = useState([
        {
            id: "01",
            eventID: "01",
            team_name: "Management",
            team_msg: "",
            members: [
                {
                    name: "Fr Basil Benny",
                    position: "Vicar",
                    phone: "",
                    photo_url: "./assets/images/management/basil.png"
                },
                {
                    name: "Shibu George",
                    position: "Secretary",
                    phone: "",
                    photo_url: "./assets/images/management/shibu.png"
                },
                {
                    name: "Aby Abraham K",
                    position: "Trustee",
                    phone: "",
                    photo_url: "./assets/images/management/aby.png"
                },
                {
                    name: "Robin Thomas",
                    position: "Joint Secretary",
                    phone: "",
                    photo_url: "./assets/images/management/robin.png"
                },
                {
                    name: "Binju Jacob",
                    position: "Joint Trustee",
                    phone: "",
                    photo_url: "./assets/images/management/binju.png"
                },
                
                {
                    name: "Shibu Mathew",
                    position: "Headmaster Sundayschool",
                    phone: "",
                    photo_url: "./assets/images/management/shibuM.png"
                },
                {
                    name: "Teena Thomas",
                    position: "Secretary Vanithasamajam",
                    phone: "",
                    photo_url: "./assets/images/management/teena.png"
                },
                
                {
                    name: "Philip Alias",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/philip.png"
                },
                {
                    name: "Eldho Joseph",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/eldho.png"
                },
                {
                    name: "Nisha Peter",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/nisha.png"
                },
                {
                    name: "Jijin George Kunnathu",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/jijin.png"
                },
                {
                    name: "Rony Thomas",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/rony.png"
                },
                {
                    name: "Eldhose Kuriakose",
                    position: "Ex.Officio",
                    phone: "",
                    photo_url: "./assets/images/management/eldhose.png"
                },
                {
                    name: "Joby Joseph",
                    position: "Ex.Officio",
                    phone: "",
                    photo_url: "./assets/images/management/joby.png"
                },
                {
                    name: "Dr.Sajith Paul",
                    position: "UK Council Member",
                    phone: "",
                    photo_url: "./assets/images/management/sajith.png"
                },
                {
                    name: "Rajesh Kuruvilla",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/management/rajesh.png"
                }
            ]
        },
        {
            id: "16",
            eventID: "16",
            team_name: "Acolytes",
            team_msg: "Mor Baselios Church is blessed with a dedicated group of Acolytes who play a vital role in enhancing the spiritual atmosphere of the Holy Mass. Through their reverent service at the altar, they assist the priest and contribute to the smooth and sacred flow of the liturgy. Their devotion and presence help make each Mass a deeply divine and meaningful experience for the entire congregation.",
            members: []
        },
        {
            id: "17",
            eventID: "17",
            team_name: "Choir",
            team_msg: "At Mor Baselios Church in Derby, we have an enthusiastic Worship Choir that's open to anyone with a passion for music and worship. Whether you're a seasoned singer or just starting out, we'd love for you to join us. We welcome vocalists of all abilities, with a focus on learning and growing together. Our repertoire includes both contemporary worship songs and traditional hymns, creating a vibrant and uplifting sound. We aim to foster a joyful atmosphere while deepening our faith and building strong connections. The choir regularly leads worship during our Sunday services and also performs at special church events and community gatherings.",
            members: []
        },
        {
            id: "15",
            eventID: "15",
            team_name: "Sunday School",
            team_msg: "Sunday School is one of the most important spiritual organizations in the Syriac Orthodox Church. Through Sunday School, the children develop an succinct understanding of the Holy Bible and build a strong foundation for Christian Living. Teachers instil in our children an understanding that God has a plan for each of them, and help guide them in following God’s will. In Sunday School, students are taught to build a personal commitment to following Jesus and obeying the teachings of the Holy Church which have been passed down from the Apostles to today.",
            members: [
                {
                    name: "Shibu Mathew",
                    position: "Headmaster Sundayschool",
                    phone: "",
                    photo_url: "./assets/images/management/shibuM.png"
                },
            ]
        },
        {
            id: "18",
            eventID: "18",
            team_name: "Youth Association",
            team_msg: "To commemorate the 15th anniversary of MBJSO Church, a Youth Association was established on August 18, 2024. The organization aims to inspire and empower the youth to engage in charitable activities while fostering stronger connections among the young members of the church, thus deepening their faith and experiencing God's grace",
            members: [
                {
                    name: "Fr Basil Benny",
                    position: "President",
                    phone: "",
                    photo_url: "./assets/images/management/basil.png"
                },
                {
                    name: "Libu Joseph",
                    position: "Secretary",
                    phone: "",
                    photo_url: "./assets/images/youth/libu.png"
                },
                {
                    name: "Midhun K Saju",
                    position: "Treasurer",
                    phone: "",
                    photo_url: "./assets/images/youth/midhun.png"
                },
                {
                    name: "Rosilin Roy",
                    position: "Joint Secretary",
                    phone: "",
                    photo_url: "./assets/images/youth/rosilin.png"
                },
                {
                    name: "Agin Alias",
                    position: "Convenor",
                    phone: "",
                    photo_url: "./assets/images/youth/agin.png"
                },
                {
                    name: "Philip Alias",
                    position: "Coordinator",
                    phone: "",
                    photo_url: "./assets/images/management/philip.png"
                },
                
                {
                    name: "Thomas Joshi",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/youth/thomas.png"
                },
                {
                    name: "Basil Jose",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/youth/basilj.png"
                },
                {
                    name: "Basil K Mathai",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/youth/basilk.png"
                },
                {
                    name: "Bijo P Kuriakose",
                    position: "Member",
                    phone: "",
                    photo_url: "./assets/images/youth/bijo.png"
                }
            ]
        },
        {
            id: "19",
            eventID: "19",
            team_name: "Morth Mariayam Vanitha Samajam",
            team_msg: "Mor Baselios Women's Association is dedicated to organizing prayer groups that bring together all the women in the parish. Together, they sing hymns and pray to God for their personal needs, as well as the needs of the church and community, inspired by the intercessory example of Saint Mary, the Holy Mother of Jesus. The association is also committed to supporting disadvantaged women within the church and local community, extending a helping hand to those in need as part of its core mission.",
            members: [
                {
                    name: "Fr Basil Benny",
                    position: "President",
                    phone: "",
                    photo_url: "./assets/images/management/basil.png"
                },
                {
                    name: "Teena Thomas",
                    position: "Secretary",
                    phone: "",
                    photo_url: "./assets/images/management/teena.png"
                },
                {
                    name: "Merin Eldho",
                    position: "Trusty",
                    phone: "",
                    photo_url: "./assets/images/management/merin.png"
                }
            ]
        },
        {
            id: "20",
            eventID: "20",
            team_name: "Prayer Groups",
            team_msg: "St Paul's Prayer group. Derby & St Mary's Prayer group. Nottingham",
            members: [
                {
                    name: "Fr Basil Benny",
                    position: "President",
                    phone: "",
                    photo_url: "./assets/images/management/basil.png"
                },
                {
                    name: "Mini Joseph",
                    position: "Secretary St Mary's Prayer",
                    phone: "",
                    photo_url: "./assets/images/vanitha/mini.png"
                },
                {
                    name: "Manu Zackaria",
                    position: "Vice president St Mary’s Prayer",
                    phone: "",
                    photo_url: "./assets/images/vanitha/manu.png"
                },
                {
                    name: "Bindhu Eldhose",
                    position: "Secretary St Paul's Prayer",
                    phone: "",
                    photo_url: "./assets/images/management/bindu.png"
                },
                {
                    name: "Binju Jacob",
                    position: "Vice president St Paul's Prayer",
                    phone: "",
                    photo_url: "./assets/images/management/binju.png"
                },
            ]
        },
        {
            id: "21",
            eventID: "21",
            team_name: "Student Movement",
            team_msg: "",
            members: []
        }
    ])

    const location = useLocation(); // To access the current URL path
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);
    const [selectedOrganisationGallery, setSelectedOrganisationGallery] = useState(null);

    // useEffect to set the organisation based on URL
    useEffect(() => {
        if (location.pathname === RENDER_URL.ABOUT_MANAGEMENT_URL) {
            const sundaySchool = ourManagement.find(org => org.id === "01");
            setSelectedOrganisation(sundaySchool);
        } else if (location.pathname === RENDER_URL.ABOUT_ALTER_URL) {
            const youthAssociation = ourManagement.find(org => org.id === "16");
            setSelectedOrganisation(youthAssociation);
        }
        else if (location.pathname === RENDER_URL.ABOUT_CHOIR_URL) {
            const vanithaAssociation = ourManagement.find(org => org.id === "17");
            setSelectedOrganisation(vanithaAssociation);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (selectedOrganisation) {
            console.log(selectedOrganisation);
            //loadOrganisationGallery();
            loadOrganisationGallery(selectedOrganisation)
        }
    }, [selectedOrganisation]);

    const loadOrganisationGallery = () => {

        let data = {
            eventId: selectedOrganisation.eventID
        }

        loadEventGallery(data).then((response) => {
            console.log(response);
            if (response.data.success) {
                setSelectedOrganisationGallery(response.data.data)
            }
        }).catch((error) => {
            console.log(error);
        })

    }



    return (<>

        <>
            <GuestHeader></GuestHeader>

            <header className="py-5">
                <div className="container px-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-6">
                            <div className="text-center my-5">
                                <h1 className="fw-bolder mb-3">MOR BASELIOS JACOBITE SYRIAN ORTHODOX CHURCH</h1>
                                <p className="lead fw-normal text-muted mb-4"> Under Malankara
                                    Syrian Orthodox Church's (MSOC) Patriarchal Vicariate in the UK</p>
                                {/* <a className="btn btn-primary btn-lg" href="#scroll-target">Read our story</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <GuestTeam team_data={selectedOrganisation ? [selectedOrganisation] : []}></GuestTeam>
            <GuestOrganisationGallery team_data={selectedOrganisationGallery} />

        </>


    </>)
}

export default GuestAdministration;